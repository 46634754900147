
.roles-container {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1rem;
}

#accordian-header {
  background-color: rgba(var(--darkgr--rgb), 0.95);
  border-color: rgba(var(--darkgr--rgb), 0.95);
}

.cards-style {
  box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
  border: 1px solid rgba(0,0,255,0.1);
}

#inner-role-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}

#trash {
  justify-self: flex-end;
  padding: 1px 5px;
}

#cardDeck {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  width: 100%;
  justify-items: inherit;
  gap: 3%;
  padding-left: 8px;
}

@media screen and (max-width: 700px) {
    .roles-container {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
    }
  
    #cardDeck {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      /* margin-bottom: -rem; */
    }
}

@media screen and (max-width: 600px) {
  #cardDeck {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 0;
    gap: 1%;
    /* margin-bottom: -rem; */
  }
}