/* NEW BANNER */ 

.hero-banner {
  max-width: 1024px;
  min-height: 360px;
   
  background: url('https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80');
  
  /*background: url('https://raa-live-images.s3.us-east-2.amazonaws.com/mfae.png');
  */padding: 10px 0;
  color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .3),0 3px 6px rgba(0, 0, 0, .05);
}

.card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, .3),0 3px 6px rgba(0, 0, 0, .05);
}

/* Event Banner */
.event-hero-banner {
  background: url('https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80');
  max-width: 100vw;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(90%, 100%));
  grid-template-rows: 1fr;
  grid-gap: 10px;
}

.event-hero-banner .container-image {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.event-hero-banner .container-text {
    display: flex;
    justify-content: center;
    align-items: left;
    color: #FFFFFF; 
    flex-direction: column;
    margin-right: 5px;
}

.event-hero-banner .container-text h2 {
  font-size: 35px;
  font-weight: bolder;
}

.event-hero-banner .container-text p {
  font-size: 25px;
  font-weight:bolder;
}


.hero-banner .row {
  margin: 0;
  width: 100%;
  min-height: 360px;
}

.banner-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-image {
  width: 300px;
  height: auto;
  border-radius: 3px;
}

.banner-events-text {
  color: #fff;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 350px) {
  .event-hero-banner {
    max-width: 100vw!important;
    min-height: 320px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 320px));
    margin: auto;
    justify-content: center;
  }

  .event-hero-banner .banner-events-text {
    display: flex;
    flex-direction: column!important;
    padding: 10px;
    justify-content: center;
    align-items: left;
  }
  
}
@media only screen and (min-width: 576px) {
  .event-hero-banner {
    max-width: 80vw!important;
    min-height: 320px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 320px));
  }

  .event-hero-banner .banner-events-text {
    display: flex;
    flex-direction: column!important;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) {
  .event-hero-banner {
    margin: auto;
    margin-top: 54px;
    max-width: 640px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
  }
  
  .event-hero-banner .container-image {
      width: 90%;
      height: auto;
      max-height: 450px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  
  .event-hero-banner .container-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1065px) {
  .hero-banner {
    margin: 50px auto;
  }
  .banner-image {
    width: 350px;
  }
}