.navbar-brand a {
  color: #F3E8EE;
}
.navbar-brand a:hover {
  color: cadetblue;
  text-decoration: none;
}
.Navigation {
  background-color: var(--nav-test-color);
  width: 100%;
  transition: all 500ms ease-in-out;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .3),0 3px 6px rgba(0, 0, 0, .05);
}
.Navigation-inner {
  max-width: 1000px;
  width: 100%;

  transition: all 500ms ease-in-out;
}
.nav-link, #nav-dropdown {
  color: #fff !important;
}
#links a {
  text-decoration: none;
  color: #444;
  font-size: 1.3rem;
}
#links a:hover {
  text-decoration: none;
  transition: all 0.1s;
}
.dropdown-nav-link:focus {
  color: #dde !important;
}
.selected {
  border-bottom: 2px solid seashell;
  transition: ease-in 200ms color;
}
.side {
  display: flex;
  justify-content: flex-start;
}

#signup-btn {
  margin: 0; /** reset */
  margin-left: .5rem;
}


@media (min-width: 999px) {
  .hide {
    display: none;
  }
}

/* @media (min-width: 770px) and (max-width: 890px) {
  .hide {
    justify-self: flex-start;
  }
} */


@media screen and (min-width: 993px) {
  .Nav li {
    /* margin-right: 10px; */
    /* align-self: center; */
    /* justify-content: center; */
  }
  .hide {
    margin-right: 10px;
    align-self: center;
    /* justify-content: center; */
  }
  .Navigation-inner {
    max-width: 95%;
  }
}

@media screen and (max-width: 992px) {
  .hide li {
    text-decoration: none;
    color: #F3E8EE;
    font-size: 1.3rem;
    margin: 0.5rem 1rem 0.5rem 0rem;
  }
}

@media screen and (max-width: 770px) {

  #signup-btn {
    margin: 0; /** reset */
    margin-top: .5rem;
  }

  .dropdown-menu {
    margin-bottom: .5rem !important;
  }

  .Navigation {
    display: flex;
    justify-content: center;
  }
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }
  #links a:hover {
    backdrop-filter: brightness(100%);
  }
}

@media only screen and (min-width: 1000px) {
  .navbar {
    /* padding-left: 0 !important;
    padding-right: 0 !important; */
  }
}
