.input-and-images-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
}

#imageUploadInput, #imageUploadInputDisabled {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
#imageUploadInput + label, #imageUploadInputDisabled + label {
    color: white;
    background-color: yellowgreen;
    display: inline-block;
    cursor: pointer;
    width: 75px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 .25rem !important;
}
#imageUploadInputDisabled + label {
    background-color: firebrick;
    filter: brightness(0.9);
    cursor: default;
}
#imageUploadInput + label * {
    pointer-events: none;
}
#imageUploadInput:focus + label,
#imageUploadInput + label:hover {
    filter: brightness(0.95);
}
#imageUploadInput:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.CreatePage {
    display: grid;

    grid-template:
    "t t t t" 0.75fr 
    "d d d d" 1fr
    "i i i i" 1fr
    "f f f f" 0.25fr / 1fr 1fr 1fr 1fr;
}

.top {
    grid-area: t;
    margin: 1em;
}

.display {
    grid-area: d;
}

.createItem {
    grid-area: i;
}

.createEvent {
    /* grid-area: e; */

}

#eventscreateform {
    
}

.item-form input#formHorizontalEmail::placeholder {
    color: #ccc;
}

.cards {
    /* border: 1px solid black; */
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    border-radius: 0.75rem;
    padding: 1em;
    margin: 1em;
    margin-bottom: 1.5em;
}

.addNewLink {
    padding: 2px 7px 5px 7px;
    margin-top: 10px;
    margin-left: 2px;
    border: 1px solid rgba(125, 198, 255, 0.27);
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    background-color: #7dc6ff;
    color: white;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.50);
}

@media screen and (max-width: 600px) {
    .cards {
        margin: 0;
    }
}