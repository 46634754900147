.Contact-wrapper {
  width: 100%;
  height: calc(100vh - 63px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.Contact-content {
  background-color: #fff;
  min-width: 350px;
  min-height: 380px;
  padding: 50px;
  border-radius: 3px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);

  display: flex;
  flex-direction: column;
  align-items: center;
}