.Item-container {
  /* background-color: #ccc; */
  width: 100%;
  min-height: calc(100vh - 7vh); 
  /* height: 100%; */

  /* background: #d8eafe; */

  display: flex;
  align-items: center;
  justify-content: center;
}

.item-content {
  /* margin: 0 auto; */

  border: 1px solid gray;
  border-radius: 1.5%;

  box-shadow: 0 0 10px rgba(0,0,0,0.5);

  background: white;

  display: grid;

  grid-template: 
  "i content" .75fr
  "i content" .75fr / .75fr 1fr;
}

.item-right {
  grid-area: i;
  height: auto; 
  min-width: inherit;
}

.item-right .item-img {
  width: 100%;
}


.item-left {
  grid-area: content; 
  padding: 2rem 1rem 0 2rem;

  display: grid;
  grid-template: 
  "name" .50fr
  "desc" 2fr
  "button" 1fr / 1fr;
}

.item-description {

  padding-top: 10px;
}

.AutoBid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
}

.input-group #form .form-control {
  background: blue;
}

@media screen and (max-width: 712px) {
  .item-content {
    /* margin: 0 auto; */
  
    border: 1px solid gray;
    border-radius: 1.5%;
  
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  
    background: white;
  
    display: grid;
  
    width: 50%;
    max-height: 25%;

    grid-template: 
    "i" .35fr
    "content" .75fr / 1fr;
  }

  .item-left {
    grid-area: content; 
    padding: 1rem 1rem 0 1rem;

    

    display: grid;
    grid-template: 
    "name" .50fr
    "desc" 2fr
    "button" 1fr / 1fr;
  }

  .item-name {
    justify-self: center;
    padding-left: 0px;
    padding-right: 10px;
  }

  .item-right {
    /* grid-area: i; 
    max-height:100%; 
    max-width: 100%;
    height: 50%; */
  }
  
  .item-right .item-img {
    /* max-height: 100%;
    width: 100%; */
  }
}

.sugg.btn.btn-primary {
  font-size: 1rem;
  font-weight: 500;
  border-radius: 7px;
  padding: 0 0.5rem;
  transition: all 300s ease-in-out;
}

@media screen and (max-width: 600px) {

  .Item-container {
    min-height: calc(100vh - 144px); 
  }

}
@media screen and (max-width: 560px) {

  .item-content {
    /* margin: 0 auto; */
  
    border: 1px solid gray;
    border-radius: 1.5%;
  
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  
    background: white;
  
    display: grid;
  
    width: 60%;
    /* max-height: 15%; */

    grid-template: 
    "i" .35fr
    "content" .75fr / 1fr;
  }

  .Item-container {
    min-height: calc(100vh - 144px); 
  }

}

@media screen and (max-width: 500px) {

  #yolo .form-control {
    max-width: 60%;
  }
  #autoBid_input {
    max-width: 50%;
  }
  .item-content {
    /* margin: 0 auto; */
  
    border: 1px solid gray;
    border-radius: 1.5%;
  
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  
    background: white;
  
    display: grid;
  
    width: 100%;
    max-height: 25%;

    grid-template: 
    "i" .35fr
    "content" .75fr / 1fr;
  }

  .Item-container {
    min-height: calc(100vh - 144px); 
  }

}
