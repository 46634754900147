.footer {
    padding-right: 50px;
    padding-left: 50px;
    margin: 0;
    background-color: var(--nav-test-color);
    height: 10vh;
    display: flex;
    flex-flow: column;
    padding-top: 12px;
    font-size: .8rem;
    position: absolute;
    width: 100%;
}

.copyright-section {
  float: right;
}

.copyright-section-2 {
  float: left;
}

.footer p {
    color: white;
}

.footer h4 {
    margin-top: .25rem;
    color: rgb(139, 32, 32);
    padding: 0;
    background-color: black;
    font-weight: 300;
    font-size: 1.3rem;
}

.footer a {
    text-decoration: none;
    color: #F3E8EE;
  }
  .footer a:hover {
    color: #00ccff;
    text-decoration: none;
    backdrop-filter: brightness(90%);
    transition: all 0.1s;
  }

  .fa-heart {
    animation: pulse 1s linear infinite;
}
  
@keyframes pulse {
    0%{
      transform: scale(1);
      opacity: .25;
    }
    20%{
        transform: scale(1);
        opacity: 1;
      }
      35%{
        transform: scale(1.5);
        opacity: .55;
      }
    50%{
      transform: scale(1.3);
      opacity:1;
    }
    100%{
      transform: scale(1);
      opacity:.75;
    }
}

@media (max-width: 380px) {
    .footer {
        align-items: center; 
        display: flex;
        padding-right: 5px;
        padding-left: 5px;
    }

    .copyright-section-2 {
      float: center;
    }

    .footer-col-2 {
      display:none;
      padding-left: 0px;
    }


  }