.thicc-font {
  font-weight: 600;
}

.input-and-images-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
}

#imageUploadInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
#imageUploadInput + label {
  color: white;
  background-color: tomato;
  display: inline-block;
  cursor: pointer;
  width: 75px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 .25rem !important;
}
#imageUploadInput + label * {
  pointer-events: none;
}
#imageUploadInput:focus + label,
#imageUploadInput + label:hover {
background-color: yellowgreen;
}
#imageUploadInput:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}