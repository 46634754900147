:root {
  /* apply these as background colors until official scheme is made */
  --home-test-color: #fff;
  --nav-test-color: #353744;
  --gallery-test-color: #353744;
  --card-test-color: #c9e9f5;
  
  --darkgr: #273043; /* darkest */
  --darkgr--rgb: 39, 48, 67;
  --gray: #9197AE;  /* steel */
  --lBLue: #BDD4E7;
  --clay: #637074;  
  --white: #F8F3F1; /* lightest */
  --altWhite: #F5F5F5;

  --max-width: 1200px;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1);
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: rgb(133, 188, 233); */
  background: var(--gallery-test-color);
  /* border-radius: 3px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #BDD4E7;
}

body {
  overflow-x: hidden;
  height: 100%;
}

.App {
  background-color: #fff;
  min-height: 100%;
}