#shazam-cont #nav {
    grid-area: sn;

    background: var(--darkgr);
    color: var(--white);

    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

    position: fixed;
    top:0;
    height:100%;
    
    width: 280px;

    display: grid;

    grid-template-rows: 58px 40px auto;
    grid-template-columns: auto;

    grid-template-areas: 
    "top-nav"
    "search"
    "components";

}

#nav #logo {
    grid-area: top-nav;

    display: flex;
    justify-content: flex-start;
    align-self: center;

    font-size: 1.25em;
    letter-spacing: .03em;

    padding: 0 0 0 20px;
    max-height: calc(100vh - 90.5vh);

}

#nav .search-cont {
    grid-area: search;
    margin-top: 0.35rem;
    display: flex;
    padding: 0;
    /* border: 4px solid black; */
    justify-items: flex-end;
}

.search-cont .nav-search {
    width: 100%;
    padding-left: 1.2rem;
}

#nav .nav-options {
    grid-area: components;

    background: var(--altWhite);
    color: var(--darkgr);
}

.nav-options ul {
    /* padding-left: 1.5rem; */
    list-style: none;
    padding-top: 0rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0rem;
}

.nav-options li {
    border: 1px solid rgba(0, 0, 0, .1);
    width: 100%;
    display: flex;
    align-items: center;
    /* padding-left: 1.2rem; */
    padding: .8rem;
}

.nav-options li:hover {
    box-shadow: 0 0px 3px rgba(0, 0, 0, .5);
}

.nav-options i {
    margin-right: 0.3rem;
    margin-left: 0.5rem;
}

.nav-options li a {
    color: var(--clay);
}
.nav-options li a:hover {
    color: var(--clay);
    text-decoration: underline!important;
}

.nav-options a {
    text-decoration: none;
}

