.body {
  overflow: hidden;
}

.Login-wrapper, .Signup-wrapper {
  width: 100%;
  height: calc(100vh - 65px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.Login-content, .Signup-content {
  background-color: #fff;
  max-width: 350px;
  min-height: 380px;
  padding: 50px;
  border-radius: 3px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.suggestion-text {
  margin-top: 10px;
  color: hsl(199, 100%, 50%);
  cursor: pointer;
}

.SignUpAlert {
  border: 1px solid green;
  border-radius: 3px;
  box-shadow: 3px 3px 7px rgba(0,0,0,0.5);
  background: green;
  color: white;
  font-size: 1.1rem;
  padding: 0.5rem;
  
  position: absolute;
  top: 80px;
  right: 10px;

  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  /* transition: opacity 200ms ease-in-out; */
}

.SignUpOn {
  transform: translateX(0px);
  opacity: 1;
}

.SignUpOff {
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  transform: translateX(250px);
  opacity: 0;
}

input[type="email"], input[type="password"] {
  margin: 10px 0;
}
.signup-form input[type="text"] {
  margin: 10px 0;
}

.checkout-container input[type="text"] {
  margin-bottom: 10px;
}

@media only screen and (min-width: 600px) {
  .Login-wrapper, .Signup-wrapper {
    height: calc(100vh - 65px);
  }
}