.Gallery-outer {
  background-color: var(--gallery-test-color);
  width: 100%;
  min-height: 55vh;
  padding: 2rem 3rem;
}

.Gallery-inner {
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-gap: 2em;
  justify-content: center;
}
.Gallery-outer h1 {
  margin-bottom: 2rem;
  color: #fff;
}

.image-outer {
  overflow: hidden;
}
.image-will-scale {
  transform: scale(1.0);
  object-fit: cover;
  object-position: top center;
}
.image-will-scale:hover {
  transform: scale(1.3);
  transition: ease all .3s;
}

.visit-event-link:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.item-ended-msg {
  filter: brightness(170%);
  color: grey;
}
.item-ended {
  filter: brightness(50%);
}

.card-text::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.card-text::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}

/* Handle */
p.card-text::-webkit-scrollbar-thumb {
  /* background: rgb(133, 188, 233); */
  background: var(--gallery-test-color);
  border-radius: 3px;
}

/* Handle on hover */
p.card-text::-webkit-scrollbar-thumb:hover {
  background: #BDD4E7;
}

@media only screen and (min-width: 555px) {
  .Gallery-inner {
    max-width: 400px;
  }
}

@media only screen and (min-width: 600px) {
  .Gallery-outer {
    min-height: 54vh;
  }
}

@media only screen and (min-width: 750px) {
  .Gallery-outer {
    /* min-height: 63vh; */
  }
  .Gallery-inner {
    max-width: 750px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 44%));
    /* grid-gap: 2.1em; */
  }
}

@media only screen and (min-width: 800px) {
  .Gallery-outer {
    /* min-height: 53.9vh; */
  }
}

@media only screen and (min-width: 900px) {
  .Gallery-inner {
    /* max-width: 1000px; */
    grid-gap: 2em;
  }
}

@media only screen and (min-width: 1050px) {
  .Gallery-inner {
    max-width: 1000px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 31%));
  }
}