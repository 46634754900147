.myAuctions {
    background: rgba(251,250,250,1);
    border: none;
    /* margin: 1rem 5rem; */
    height: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 175px 1fr;
    grid-auto-flow: row;
    padding: 1rem;

    max-width: 1000px;
    margin: 0px auto 40px;
    overflow-x: auto;
}
.title {
    margin-top: 1rem;
    /* margin-bottom: 1rem; */
}

.title h2 {
    font-size: 3.8rem;
    /* font-size: 62px; */
    /* text-decoration: underline 10px; */
    /* border-bottom: 1px solid black; */
}
.title h2 span {
    display: inline-block;
    border-bottom: 5px solid black;
    padding-bottom: 15px;
    /* margin-bottom: 10rem; */
}

.content {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 3rem;
    grid-auto-flow: row;
}

.items_won {
    /* background: green; */
    margin-bottom: 1rem;
    border: 1px solid gray;
    min-height: 2rem;
    height: "fit-content";    
}

.items_won h2 {
    margin: 1rem;
    font-weight: 300;
}

.items_won .wonTable {
    margin: 1rem;
}

.items_active {
    margin-top: 1rem;
    /* background: red; */
    border: 1px solid gray;
    min-height: 10rem;    
}
.items_active h2 {
    margin: 1rem;
    font-weight: 300;
}

.items_active .activeTable {
    margin: 1rem;
}

.spinner-cont {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 20vh;
}

@media only screen and (min-width: 1000px) {
    .myAuctions {
        margin: 40px auto 40px;
        border: 1px solid black;
    }
}