.card-type {
  padding: 10px !important;
  margin-left: 5px;
  margin-bottom: 10px;
}

.item-description-content {
  width: 90%;
  margin: 0 auto 10px;
}

.checkout-container .credit-card input[type="text"] {
  margin: 10px;
  padding: .375rem .75rem;
  font-size: 1rem;
  height: calc(1.5em + .75rem + 2px);
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.paypal-btn {
  width: 250px;
  height: 50px;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #3178c0 !important;
}