.button {
    background-color: #ffffff;
    border: solid;
    color: #00008b;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 25px;
  }
  .button:hover {
    background-color: #ddd;
  }
  
  