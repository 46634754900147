.item-cont {
    /* background: #000; */
    display: grid;
    height: 94vh;
    width: 100%;
    overflow: hidden;

    padding: 1rem;

    grid-gap: 1rem 1rem;
    grid-template-columns: auto minmax(25.5vw, 50vw);
    grid-template-rows: 40px 1fr 1fr minmax(30vh, 40vh) 50px;

    grid-template-areas: 
    "n n"
    "p t" 
    "p b" 
    "d b" 
    "d b";
}

.return-btn-text {
    float: right!important;
    font-family:'Segoe UI', sans-serif!important;
    margin-left: 10px;
}

.backBtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#item-form input#formHorizontalEmail::placeholder {
    color: #ccc;
}
/* .item-cont {
    background: #000;
    display: grid;
    height: 94vh;
    width: 100%;
    overflow: hidden;

    padding: 1rem;

    grid-gap: 1rem 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 40px 1fr 1fr 1fr 1fr;

    grid-template-areas: 
    "n n n n"
    "p p t t" 
    "p p d d" 
    "b b d d" 
    "b b d d";
} */

.item-header {
    grid-area: n;
    margin: 0;
    padding: 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    border: 1px solid black;
    justify-items: center;
    background-color: #353744;
}

.item-boxs {
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 3px;
    background: var(--altWhite);
    box-shadow: 0px 1px 7px rgba(0,0,0,0.9)
}

.item-pic-cont {
    grid-area: p;
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
}

.item-img {
    max-width: 100%;
    max-height: 100%;
}

/* .item-desc-cont.card-body {
    overflow-y: auto;
    max-height: 30vh;
} */

.item-title-cont {
    grid-area: t;
    display: flex;
    padding: 1rem;
}

.item-title-cont h1 {
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: 400;
    align-self: center;
}

.item-title-cont h4 {
    position: relative;
    top: 0;
    left: 0;
}

.item-desc-cont {
    grid-area: d;
    padding: .25rem;
}

.item-buy-cont {
    grid-area: b;
    height: 100%;
}

.item-pay-suggestions {
    margin: 1rem 0;
    display: grid; 
    grid-auto-flow: column;
    grid-template-columns: 100px 1fr; 
    width: 100%;
}

.pills-content {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-gap: 5px;
}

.SpinContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sugg.btn.btn-primary {
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    padding: 0 0.5rem;
    transition: all 300s ease-in-out;
}

.AutoBid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-row: 1fr;
}

@media (min-width: 1200px) {
    .item-pay-suggestions {
        width: 30%;
    }
}
@media (min-width: 850px) {
    .item-pay-suggestions {
        width: 80%;
    }
}

@media only screen and (max-width: 950px) {
    .item-pay-suggestions {
        margin: 0.3rem 0;
        display: grid;
        grid-template:
        "t"
        "p"; 
        /* grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr ;
        grid-template: 
        "t t"
        "p p" 
        "p p";
        grid-gap: 1rem;  */
        grid-gap: .5rem;
        width: 30%; 
    }
    
    .item-pay-suggestions h4 {
        grid-area: t;
    }
}

@media only screen and (max-width: 850px) {
    .item-cont-cont {
        overflow: hidden;
    }
    
    .item-cont {
        grid-gap: 1rem 1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 40px 1fr 1fr 1fr 1fr;
        
        /* overflow: hiddenq; */
        overflow: scroll;

    
        grid-template-areas: 
        "n"
        "p" 
        "t" 
        "d" 
        "b";
        
    }
    
    .item-buy-cont {
        height: 100%;
        margin-bottom: 2rem;
    }

    .item-pay-suggestions {
        margin: 1rem 0;
        width: 50%;
        display: grid;
        grid-template: "t p" / 1fr 1fr;
    }
    .pills-content {
        grid-gap: .75rem;
    }
    
}